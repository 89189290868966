import React from 'react';

import sign from '../assets/images/sign-new.jpg';
import videoIntroLarge from '../assets/video/videoIntro-1080.mp4';
import videoThumb from '../assets/video/videoIntroThumb.png';
import Guarantee from '../components/guarantee';
import Layout from '../components/layout';
import SEO from '../components/seo';

function About({ data }) {
  // const services = data.services.edges;

  return (
    <Layout headerClass='relative bg-black' heroSize='20rem' heroImage={sign}>
      <SEO
        title='About Us | How We Can Help'
        description='City of Oaks Cremation is a full-service funeral home in Raleigh serving all of NC and beyond. We do affordable cremation, immediate burial, traditional funerals'
      />
      <div className='flex flex-col mt-10 md:mt-16'>
        <p className='mb-2 text-4xl text-green-700 font-serif self-center'>
          We're family-owned, family-operated, and family-oriented.
        </p>
        <h1 className='py-4'>
          At City of Oaks Cremation we have an all-inclusive, low cost approach
          for cremation, burials, or traditional funerals.
        </h1>
        <p className='py-4'>
          The families we serve are typically educated, practical,
          environmentally conscious and economically sound decision makers. More
          frequently, families are deciding to bypass the lavish floral
          displays, catered receptions, expensive hearses, and family cars or
          limousines that have often been associated with traditional American
          funerals of the past.
        </p>
        <p>
          We provide each family with a Licensed Funeral Director who will work
          with you from start to finish. Our team takes pride in being able to
          assist families with a wide range of cultures, religions, economical,
          and other influencing factors. We provide compassionate care and
          understanding to families at the time of a pending death by making
          sure they are informed of what steps will follow and how we will be
          there to assist them through the entire process. Our Funeral Directors
          provide the reassuring voice that families rely on while going through
          a difficult time when a loved one passes away.
        </p>
      </div>
      <div className='w-full mt-6'>
        <video style={{ background: `#4e5458` }} poster={videoThumb} controls>
          <source src={videoIntroLarge} />
        </video>
      </div>

      <Guarantee />
    </Layout>
  );
}

export default About;
